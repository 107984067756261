/**
 * Local user class, for saving login details
 */
export class LocalUser {
  /**
   * User first name
   */
  public firstName: string;

  /**
   * User last name
   */
  public lastName: string;

  /**
   * User post code
   */
  public postCode: string;

  /**
   * Create a local user
   */
  constructor(firstName: string, lastName: string, postCode: string) {
    this.firstName = firstName;
    this.lastName = lastName;
    this.postCode = postCode;
  }

  /**
   * Load the local user, if one has been stored
   */
  public static load(): LocalUser | undefined {
    const value = localStorage.getItem("localUser");
    if (value) {
      try {
        return JSON.parse(value);
      } catch (err) {
        // Ignore
      }
    }
    return undefined;
  }

  /**
   * Store the user to local storage
   */
  public static store(user: LocalUser): void {
    console.log(`Store user '${user.firstName} ${user.lastName}'`);
    const value = JSON.stringify(user);
    localStorage.setItem("localUser", value);
  }

  /**
   * Clear the user from local storage, if present
   */
  public static clear(): void {
    console.log("Clear stored local user");
    localStorage.removeItem("localUser");
  }
}
