
import { defineComponent } from "vue";
import Header from "../components/Header.vue";
import City from "../svg/City.vue";

export default defineComponent({
  name: "Hamilton",
  components: {
    Header,
    City,
  },
});
