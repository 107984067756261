
import { defineComponent } from "vue";
import Header from "../components/Header.vue";
import Nicholas from "../svg/Nicholas.vue";
import Laura from "../svg/Laura.vue";
// import ChristTheKing from "../svg/ChristTheKing.vue";
// import LiunaStation from "../svg/LiunaStation.vue";
// import EnvelopeOpenText from "../svg/EnvelopeOpenText.vue";
// import Gifts from "../svg/Gifts.vue";
// import City from "../svg/City.vue";
// import AngleBottomStart from "../svg/AngleBottomStart.vue";
// import AngleTopEnd from "../svg/AngleTopEnd.vue";
import NicholasAndLaura from "../svg/NicholasAndLaura.vue";
import At from "../svg/At.vue";

export default defineComponent({
  name: "Home",
  props: {},
  components: {
    Header,
    Nicholas,
    Laura,
    // ChristTheKing,
    // LiunaStation,
    // EnvelopeOpenText,
    // Gifts,
    // City,
    // AngleBottomStart,
    // AngleTopEnd,
    NicholasAndLaura,
    At,
  },
});
