
import { defineComponent } from "vue";
import WeddingApi from "../model/WeddingApi";
import { Registry } from "../model/Registry";
import RegistryItem from "./RegistryItem.vue";
import { RegistryItem as RegistryItemModel } from "../model/RegistryItem";
import GiftItem from "./GiftItem.vue";

interface CategorizedRegistry {
  category: string;
  registry: RegistryItemModel[];
}

export default defineComponent({
  components: {
    RegistryItem,
    GiftItem,
  },
  async mounted() {
    await this.fetchRegistry();
  },
  data() {
    let data: {
      registry?: Registry;
      loading: boolean;
      loadError?: string;
    } = {
      registry: undefined,
      loading: true,
      loadError: undefined,
    };
    return data;
  },
  methods: {
    async fetchRegistry(callback?: () => void): Promise<void> {
      try {
        this.registry = await WeddingApi.GetRegistry();
      } catch (err) {
        this.loadError = err;
      } finally {
        this.loading = false;

        if (callback) {
          callback();
        }
      }
    },
  },
  computed: {
    categories() {
      const categories = new Map<string, CategorizedRegistry>();

      if (!this.registry) {
        return categories;
      }

      for (let registryItem of this.registry.registry) {
        const categoryName = registryItem.category || "Other";
        if (!categories.has(categoryName)) {
          categories.set(categoryName, {
            category: categoryName,
            registry: [],
          });
        }

        const category = categories.get(categoryName);

        if (category) {
          category.registry.push(registryItem);
        }
      }

      return categories;
    },
  },
});
