
import { defineComponent } from "vue";
import Header from "../components/Header.vue";
import SectionGuard from "../components/SectionGuard.vue";
import Comments from "../svg/Comments.vue";

export default defineComponent({
  name: "Contact",
  components: {
    Header,
    Comments,
    SectionGuard,
  },
});
