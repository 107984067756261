
import { defineComponent, PropType, Transition } from "vue";
import { GiftUpdate } from "../model/GiftUpdate";
import { RegistryItem } from "../model/RegistryItem";
import WeddingApi from "../model/WeddingApi";
import SpinnerButton, { SpinnerState } from "./SpinnerButton.vue";

export default defineComponent({
  components: {
    SpinnerButton,
    Transition,
  },
  data() {
    return {
      purchaseQuantity: 1,
      expanded: false,
      spinState: SpinnerState.Normal,
      updateError: "",
      notes: "",
    };
  },
  props: {
    registryItem: {
      type: Object as PropType<RegistryItem>,
      required: true,
    },
  },
  computed: {
    multipleItems() {
      return this.registryItem && this.registryItem.neededQuantity
        ? this.registryItem.neededQuantity > 1
        : false;
    },
    buttonText() {
      if (this.spinState === SpinnerState.Success) {
        return "Marked as Purchased";
      } else if (this.spinState === SpinnerState.Error) {
        return "Failed to mark";
      } else if (this.multipleItems && this.expanded) {
        return `Mark ${this.purchaseQuantity} Purchased`;
      } else if (this.multipleItems) {
        return `Mark some Purchased...`;
      } else if (this.expanded) {
        return "Mark as Purchased";
      } else {
        return "Mark as Purchased...";
      }
    },
    canIncrement() {
      return (
        this.multipleItems &&
        this.registryItem &&
        this.registryItem.neededQuantity &&
        this.purchaseQuantity < this.registryItem.neededQuantity
      );
    },
    canDecrement() {
      return this.multipleItems && this.purchaseQuantity > 1;
    },
    itemText() {
      let manufacturer = this.registryItem?.manufacturer || "";
      let model = this.registryItem?.model || "";
      return `${manufacturer} ${model}`.trim();
    },
  },
  methods: {
    increment() {
      if (this.canIncrement) {
        this.purchaseQuantity += 1;
      }
    },
    decrement() {
      if (this.canDecrement) {
        this.purchaseQuantity -= 1;
      }
    },
    success() {
      this.spinState = SpinnerState.Success;
      setTimeout(() => {
        this.$emit("updated", () => {
          const yourGifts = document.getElementById("yourGifts");
          if (yourGifts) {
            yourGifts.scrollIntoView({ behavior: "smooth" });
          }
        });
        this.spinState = SpinnerState.Normal;
      }, 3 * 1000);
    },
    error(message: string) {
      this.spinState = SpinnerState.Error;
      this.updateError = message;
      setTimeout(() => {
        this.spinState = SpinnerState.Normal;
        this.updateError = "";
      }, 8 * 1000);
    },
    click() {
      if (this.expanded) {
        this.purchaseGift();
      } else {
        this.expanded = true;
      }
    },
    async purchaseGift() {
      if (this.spinState !== SpinnerState.Normal) {
        return;
      }
      this.updateError = "";

      const update: GiftUpdate = {
        registryId: this.registryItem.recordId,
        purchasedQuantity: this.purchaseQuantity,
        purchaserNotes: this.notes,
      };
      try {
        this.spinState = SpinnerState.Spin;
        await WeddingApi.PostGift(update);
        this.success();
      } catch (err) {
        this.error(err.message || "Unknown error");
      }
    },
  },
});
