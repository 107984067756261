import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { format } from "date-fns";
import { Gift } from "./Gift";
import { GiftUpdate } from "./GiftUpdate";
import { Guest } from "./Guest";
import { LocalUser } from "./LocalUser";
import { Registry } from "./Registry";
import { Rsvp } from "./Rsvp";
import { RsvpUpdate } from "./RsvpUpdate";

export default class WeddingApi {
  /**
   * Build axios auth options from the local user
   */
  public static GetAuthOptions(): AxiosRequestConfig {
    const localUser = LocalUser.load();
    if (localUser) {
      // Safari overwrites the authorization header, so send it twice
      // This means computing the base64 ourselves.
      // This is only necessary for beta, which has basic auth on the root website
      let username = `${localUser.firstName} ${localUser.lastName}`;
      const password = localUser.postCode;
      const base64 = btoa(`${username}:${password}`);

      // We also have to translate Aunt Natália's special character before encoding
      username = username.replace("Á", "A").replace("á", "a");

      return {
        auth: {
          username: username,
          password: password,
        },
        headers: {
          "X-Safari-Authorization": `Basic ${base64}`,
        },
      };
    }

    throw new Error("No local user saved; aborting request");
  }

  /**
   * Get user details
   */
  public static async GetUser(): Promise<Guest> {
    try {
      const res: AxiosResponse<Guest> = await axios.get(
        "/api/user",
        WeddingApi.GetAuthOptions()
      );

      if (res.status !== 200) {
        throw new Error(`Request failed: status ${res.status}`);
      }
      return res.data;
    } catch (err) {
      if (axios.isAxiosError(err) && err.response?.status === 403) {
        LocalUser.clear();
        throw new Error(
          "We could not find an invitation under that name/post code"
        );
      }
      throw new Error("Unable to login: Server error");
    }
  }

  /**
   * Get RSVPs for this family
   */
  public static async GetRSVP(): Promise<Rsvp[]> {
    try {
      const res: AxiosResponse<Rsvp[]> = await axios.get(
        "/api/rsvp",
        WeddingApi.GetAuthOptions()
      );

      if (res.status !== 200) {
        throw new Error(`Request failed: status ${res.status}`);
      }

      // Augment with a server status, so we know what the server has
      // {status} as of {date}
      for (const rsvp of res.data) {
        WeddingApi.SetRsvpLastUpdated(rsvp);
      }

      return res.data;
    } catch (err) {
      throw new Error("Unable to retrieve RSVP: Server error");
    }
  }

  /**
   * Update (creating if necessary) an individual guest RSVP
   */
  public static async PostRSVP(update: RsvpUpdate): Promise<Rsvp> {
    try {
      const res: AxiosResponse<Rsvp> = await axios.post(
        "/api/rsvp",
        update,
        WeddingApi.GetAuthOptions()
      );

      if (res.status !== 200 && res.status !== 201) {
        throw new Error(`Request failed: status ${res.status}`);
      }

      WeddingApi.SetRsvpLastUpdated(res.data);
      return res.data;
    } catch (err) {
      throw new Error("Unable to send RSVP: Server error");
    }
  }

  /**
   * Get the registry
   */
  public static async GetRegistry(): Promise<Registry> {
    try {
      const res: AxiosResponse<Registry> = await axios.get(
        "/api/registry",
        WeddingApi.GetAuthOptions()
      );

      if (res.status !== 200) {
        throw new Error(`Request failed: status ${res.status}`);
      }
      return res.data;
    } catch (err) {
      throw new Error("Unable to retrieve registry: Server error");
    }
  }

  /**
   * Update (creating if necessary) a gift
   */
  public static async PostGift(update: GiftUpdate): Promise<Gift> {
    try {
      const res: AxiosResponse<Gift> = await axios.post(
        "/api/gifts",
        update,
        WeddingApi.GetAuthOptions()
      );

      if (res.status !== 200 && res.status !== 201) {
        throw new Error(`Request failed: status ${res.status}`);
      }
      return res.data;
    } catch (err) {
      throw new Error("Unable to send gift: Server error");
    }
  }

  /**
   * Update (creating if necessary) a gift
   */
  public static async DeleteGift(recordId: string): Promise<void> {
    try {
      const res: AxiosResponse<Gift> = await axios.delete(
        `/api/gifts/${recordId}`,
        WeddingApi.GetAuthOptions()
      );

      if (res.status !== 200 && res.status !== 201) {
        throw new Error(`Request failed: status ${res.status}`);
      }
    } catch (err) {
      throw new Error("Unable to delete gift: Server error");
    }
  }

  /**
   * Set last updated string on RSVP
   */
  private static SetRsvpLastUpdated(rsvp: Rsvp): void {
    if (rsvp.date) {
      const formatted = format(Date.parse(rsvp.date), "MMM d");
      rsvp.lastUpdate = `${rsvp.status} as of ${formatted}`;
    } else {
      rsvp.lastUpdate = rsvp.status;
    }
  }
}
