
import { defineComponent } from "vue";

export default defineComponent({
  name: "Navbar",
  props: {},
  data() {
    return {
      collapseButton: "navbar-toggler collapsed",
      collapseArea: "navbar-collapse collapse",
    };
  },
  methods: {
    toggleNavbar() {
      if (this.collapseArea.indexOf("show") > 0) {
        this.collapseArea = "navbar-collapse collapse";
        this.collapseButton = "navbar-toggler collapsed";
      } else {
        this.collapseArea = "navbar-collapse collapse show";
        this.collapseButton = "navbar-toggler";
      }
    },
    hideNavbar() {
      if (this.collapseArea.indexOf("show") > 0) {
        this.collapseArea = "navbar-collapse collapse";
        this.collapseButton = "navbar-toggler collapsed";
      }
    },
  },
  watch: {
    $route: {
      handler: function (): void {
        this.hideNavbar();
      },
    },
  },
});
