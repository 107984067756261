
import { defineComponent } from "vue";

import Navbar from "./components/Navbar.vue";
import Footer from "./components/Footer.vue";
import { useStore } from "./model/Store";

export default defineComponent({
  name: "App",
  setup() {
    const store = useStore();
    return {
      store,
    };
  },
  components: {
    Navbar,
    Footer,
  },
  mounted() {
    this.store.dispatch("initialLogin");
  },
});
