
import { defineComponent, PropType, Transition } from "vue";

export enum SpinnerState {
  Normal = "Normal",
  Spin = "Spin",
  Success = "Success",
  Error = "Error",
}

export default defineComponent({
  components: {
    Transition,
  },
  props: {
    state: {
      type: String as PropType<SpinnerState>,
      required: true,
    },
    text: {
      type: String as PropType<string>,
      required: true,
    },
  },
});
