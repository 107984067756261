
import { defineComponent } from "vue";
import { mapState } from "vuex";
import useVuelidate from "@vuelidate/core";
import { required, minLength } from "@vuelidate/validators";
import { LoginStatus, useStore, State } from "../model/Store";
import { LocalUser } from "../model/LocalUser";

export default defineComponent({
  setup() {
    const store = useStore();
    return {
      store,
      v$: useVuelidate(),
    };
  },
  data() {
    return {
      firstName: "",
      lastName: "",
      postCode: "",
      lastLoginError: undefined,
    };
  },
  computed: {
    ...mapState<State>({
      isLoggedIn(state: State) {
        return state.loginStatus === LoginStatus.LoggedIn;
      },
      isLoggingIn(state: State) {
        return state.loginStatus === LoginStatus.LoggingIn;
      },
      isNotLoggedIn(state: State) {
        return state.loginStatus === LoginStatus.NotLoggedIn;
      },
      initialLogin: (state: State) => state.initialLogin,
    }),
  },
  methods: {
    async login() {
      const valid = await this.v$.$validate();

      if (valid) {
        let localUser: LocalUser = {
          firstName: (this.firstName as string).trim(),
          lastName: (this.lastName as string).trim(),
          postCode: (this.postCode as string).trim(),
        };

        try {
          await this.store.dispatch("login", localUser);
        } catch (err) {
          this.lastLoginError = err.message || "Unknown error";
        }
      }
    },
  },
  validations() {
    return {
      firstName: { required },
      lastName: { required },
      postCode: { required, minLength: minLength(5) },
    };
  },
});
