
import { defineComponent } from "vue";
import Header from "../components/Header.vue";
import LoginGuard from "../components/LoginGuard.vue";
import RegistryList from "../components/RegistryList.vue";
import Gifts from "../svg/Gifts.vue";
import AngleTopEnd from "../svg/AngleTopEnd.vue";

export default defineComponent({
  name: "Registry",
  components: {
    Header,
    Gifts,
    LoginGuard,
    RegistryList,
    AngleTopEnd,
  },
});
