
import { defineComponent } from "vue";
import Header from "../components/Header.vue";
import ExclamationSquare from "../svg/ExclamationSquare.vue";

export default defineComponent({
  name: "404",
  components: {
    Header,
    ExclamationSquare,
  },
});
