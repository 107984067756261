import {
  createStore,
  Store,
  createLogger,
  useStore as baseUseStore,
} from "vuex";
import { InjectionKey } from "vue";
import { Guest } from "./Guest";
import { LocalUser } from "./LocalUser";
import WeddingApi from "./WeddingApi";

// Disable certain features in production
const debug = process.env.NODE_ENV !== "production";

// Injection key, for typings
export const key: InjectionKey<Store<State>> = Symbol();

// Login status enum
export enum LoginStatus {
  /**
   * Login in progress; show a spinner.  Terminates in NotLoggedIn or LoggedIn
   */
  LoggingIn,

  /**
   * Not logged in; prompt user to login
   */
  NotLoggedIn,

  /**
   * User is logged in
   */
  LoggedIn,
}

// State interface
export interface State {
  loginStatus: LoginStatus;
  initialLogin: boolean;
  guest?: Guest;
}

// Create store
export const store = createStore<State>({
  // Throw usage errors in dev
  strict: debug,
  // Log mutations in dev
  plugins: debug ? [createLogger()] : [],

  state() {
    return {
      loginStatus: LoginStatus.LoggingIn,
      initialLogin: true,
    };
  },

  mutations: {
    /**
     * Clear initial login state
     */
    clearInitialLogin(state: State) {
      state.initialLogin = false;
    },

    /**
     * Update login status
     */
    updateLoginStatus(state: State, status: LoginStatus) {
      state.loginStatus = status;
    },

    /**
     * Update cached guest record
     */
    updateGuest(state: State, guest: Guest | undefined) {
      state.guest = guest;
    },
  },

  actions: {
    /**
     * Login to the back end
     */
    async login({ state, commit }, user: LocalUser | undefined): Promise<void> {
      // Skip login if already logged in
      if (state.loginStatus === LoginStatus.LoggedIn) {
        commit("clearInitialLogin");
        return;
      }

      commit("updateLoginStatus", LoginStatus.LoggingIn);

      // Were we passed a new user? Write it to local storage
      if (user) {
        LocalUser.store(user);
      }

      // At this stage, we should have a user in local storage.
      // Use it to request the user endpoint to confirm it is valid
      try {
        const guest = await WeddingApi.GetUser();
        commit("updateGuest", guest);
        commit("updateLoginStatus", LoginStatus.LoggedIn);
        commit("clearInitialLogin");
      } catch (err) {
        commit("updateGuest", undefined);
        commit("updateLoginStatus", LoginStatus.NotLoggedIn);
        commit("clearInitialLogin");
        throw err;
      }
    },

    /**
     * Do an initial login to the backend, if possible
     */
    async initialLogin({ dispatch, commit }): Promise<void> {
      if (LocalUser.load()) {
        try {
          await dispatch("login");
        } catch (err) {
          console.log("Initial login failed; saved credentials were incorrect");
        }
      } else {
        commit("updateLoginStatus", LoginStatus.NotLoggedIn);
        commit("clearInitialLogin");
      }
    },

    /**
     * Logout, by throwing away saved credentials
     */
    async logout({ commit }): Promise<void> {
      LocalUser.clear();
      commit("updateGuest", undefined);
      commit("updateLoginStatus", LoginStatus.NotLoggedIn);
      commit("clearInitialLogin");
    },
  },
});

// Make retrieving store easier
export function useStore(): Store<State> {
  return baseUseStore(key);
}
