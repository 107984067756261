
import { defineComponent, PropType, Transition } from "vue";
import { Gift } from "../model/Gift";
import WeddingApi from "../model/WeddingApi";
import SpinnerButton, { SpinnerState } from "./SpinnerButton.vue";

export default defineComponent({
  components: {
    SpinnerButton,
    Transition,
  },
  props: {
    gift: {
      type: Object as PropType<Gift>,
      required: true,
    },
  },
  data() {
    return {
      spinState: SpinnerState.Normal,
      updateError: "",
    };
  },
  computed: {
    itemText() {
      let manufacturer = this.gift?.registryItem?.manufacturer || "";
      let model = this.gift?.registryItem?.model || "";
      return `${manufacturer} ${model}`.trim();
    },
    buttonText() {
      switch (this.spinState) {
        case SpinnerState.Normal:
          return "Clear gift";
        case SpinnerState.Success:
          return "Gift cleared";
        case SpinnerState.Error:
          return "Clear failed";
      }
      return "";
    },
    quantityText() {
      if (this.gift) {
        return `${this.gift.purchasedQuantity} from ${this.gift.familyName}`;
      }
      return "";
    },
  },
  methods: {
    success() {
      this.spinState = SpinnerState.Success;
      setTimeout(() => {
        this.$emit("updated", () => {
          this.spinState = SpinnerState.Normal;
        });
      }, 3 * 1000);
    },
    error(message: string) {
      this.spinState = SpinnerState.Error;
      this.updateError = message;
      setTimeout(() => {
        this.spinState = SpinnerState.Normal;
        this.updateError = "";
      }, 8 * 1000);
    },
    async deleteGift() {
      if (this.spinState !== SpinnerState.Normal) {
        return;
      }
      this.updateError = "";
      try {
        this.spinState = SpinnerState.Spin;
        await WeddingApi.DeleteGift(this.gift.recordId);
        this.success();
      } catch (err) {
        this.error(err.message || "Unknown error");
      }
    },
  },
});
