
import { defineComponent } from "vue";
import { mapState } from "vuex";
import { LoginStatus, State, useStore } from "../model/Store";
import Monogram from "../svg/Monogram.vue";

export default defineComponent({
  name: "Footer",
  components: {
    Monogram,
  },
  setup() {
    const store = useStore();
    return {
      store,
    };
  },
  computed: {
    ...mapState<State>({
      guest: (state: State) => state.guest,
      isLoggedIn(state: State) {
        return state.loginStatus === LoginStatus.LoggedIn;
      },
    }),
  },
  methods: {
    logout() {
      this.store.dispatch("logout");
    },
  },
});
