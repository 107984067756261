import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bg-dark text-white" }
const _hoisted_2 = { class: "position-relative" }
const _hoisted_3 = { class: "shape shape-fluid-x shape-bottom text-dark" }
const _hoisted_4 = { class: "shape-img pb-8 pb-md-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AngleBottomStart = _resolveComponent("AngleBottomStart")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("section", _hoisted_1, [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_AngleBottomStart)
        ])
      ])
    ])
  ]))
}