import { createApp, nextTick } from "vue";
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { store, key } from "./model/Store";
import App from "./App.vue";
import Home from "./pages/Home.vue";
// import Ceremony from "./pages/Ceremony.vue";
// import Reception from "./pages/Reception.vue";
import NotFound from "./pages/NotFound.vue";
// import Rsvp from "./pages/Rsvp.vue";
import Registry from "./pages/Registry.vue";
import Hamilton from "./pages/Hamilton.vue";
import Contact from "./pages/Contact.vue";

declare type ScrollPositionCoordinates = {
  behavior?: ScrollOptions["behavior"];
  left?: number;
  top?: number;
};

// Routes
const routes: Array<RouteRecordRaw> = [
  { path: "/", component: Home },
  // { path: "/ceremony", component: Ceremony, name: "The Ceremony" },
  // { path: "/reception", component: Reception, name: "The Reception" },
  // { path: "/rsvp", component: Rsvp, name: "RSVP" },
  { path: "/registry", component: Registry, name: "Our Registry" },
  { path: "/hamilton", component: Hamilton, name: "Hamilton" },
  { path: "/contact", component: Contact, name: "Contact Us" },
  { path: "/:pathMatch(.*)", component: NotFound, name: "Not Found" },
];

// Router
const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior: async function (
    _to,
    _from,
    savedPosition
  ): Promise<ScrollPositionCoordinates> {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { left: 0, top: 0, behavior: "smooth" };
    }
  },
});

// Title
const base = "Nicholas & Laura";
router.afterEach((to) => {
  nextTick(() => {
    document.title = to.name ? `${String(to.name)} | ${base}` : base;
  });
});

createApp(App).use(router).use(store, key).mount("#app");
