
import { defineComponent } from "vue";
import { mapState } from "vuex";
import { LoginStatus, useStore, State } from "../model/Store";

export default defineComponent({
  setup() {
    const store = useStore();
    return {
      store,
    };
  },
  computed: {
    ...mapState<State>({
      isLoggedIn(state: State) {
        return state.loginStatus === LoginStatus.LoggedIn;
      },
      isLoggingIn(state: State) {
        return state.loginStatus === LoginStatus.LoggingIn;
      },
      isNotLoggedIn(state: State) {
        return state.loginStatus === LoginStatus.NotLoggedIn;
      },
    }),
  },
});
